<template>
  <div class="min-h-screen flex flex-col">
    <Header class="pt-1 pb-2 sm:pb-3" v-if="!embed">
      <template #logowithtext>
        <LogoWithText/>
      </template>
    </Header>
    <main class="container mx-auto grow max-w-10xl p-1 sm:px-0" :class="embed ? 'mt-2' : ''">
      <slot/>
    </main>
    <Footer v-if="!embed" />
  </div>
</template>
<script setup lang="ts">
const route = useRoute();
const embed = ref(route.query.embed === "true" || false);
</script>